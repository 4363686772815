import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import colors from '../utils/colors';
import { Layout, Banner, SEO, SideBar } from '../components';
import { media } from '../utils/media';
import config from '../../config/SiteConfig';

const News = props => {
  const { uid } = props.pageContext;
  const newsItem = props.data.prismicNewsItem.data;

  return (
    <Layout>
      <SEO newsPath={uid} newsItem={newsItem} newsSEO />
      <Helmet title={`${newsItem.title.text} | ${config.siteTitle}`} />
      <Banner data={newsItem} />
      <ContentWrapper>
        <Main>
          <Title>{newsItem.title.text}</Title>
          <PostImage src={newsItem.featured_image.url} alt={newsItem.featured_image.alt} />
          <PostContent dangerouslySetInnerHTML={{ __html: newsItem.content.html }} />
        </Main>
        <SideBar />
      </ContentWrapper>
    </Layout>
  );
};

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

const Main = styled.main`
  max-width: 950px;
  width: 100%;
  margin: 5rem 2rem;

  @media ${media.tablet} {
    margin: 2rem auto;
    padding: 1rem;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
`;

const PostContent = styled.div`
  margin-top: 1rem;

  li {
    margin: 0;
  }

  a {
    color: ${colors.bigStone};
  }
`;

const PostImage = styled.img`
  margin-top: 1rem;
`;

News.propTypes = {
  pageContext: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    prismicNewsItem: PropTypes.object.isRequired,
  }).isRequired,
};

export const newsQuery = graphql`
  query CaseBySlug($uid: String!) {
    prismicNewsItem(uid: { eq: $uid }) {
      uid
      slugs
      data {
        page_title {
          text
        }
        banner_image {
          url
          alt
        }
        date
        title {
          text
        }
        content {
          html
        }
        featured_image {
          alt
          url
        }
        meta_title
        meta_description
      }
    }
  }
`;

export default News;
